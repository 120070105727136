import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './App.css';



const Marker = ({ text }) => <span className="dot">{text}</span>;

class App extends Component {
  static defaultProps = {
    center: {
      lat: 45.17308079630802,
      lng: 23.801054237717487,
    },
    zoom: 15
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleClickOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  render() {

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
      <div className="panel">
        <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()}>
          Sesizează incident
        </Button>
      </div>

      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyD1DrDBUd6GNL2EIBCxK-K0OjkTny8kbuA' }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
      >
        <Marker
          lat={45.17308079630802}
          lng={23.801054237717487}
          text="1"
        />
        <Marker
          lat={45.18308079630802}
          lng={23.801054237717487}
          text="5"
        />
        <Marker
          lat={45.18308079630802}
          lng={23.791054237717487}
          text="3"
        />
      </GoogleMapReact>
      <Dialog open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Sesizează</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sesizează o neregula în comuna Polovragi, datele tale personale vor fi ascunse.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nume complet (privat)"
            type="name"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Adresa de email (privat)"
            type="email"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="type"
            label="Tipul sesizării"
            type="type"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Descriere"
            type="description"
            fullWidth
            multiline
            rows={4}
          />
          <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()}>
            Alege punct pe hartă
          </Button>
          <br />
          <br />
          <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()}>
            Încarcă o fotografie
          </Button>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            Anulează
          </Button>
          <Button onClick={() => this.handleClose()} color="primary">
            Trimite
          </Button>
        </DialogActions>
      </Dialog>
      </div>

    );
  }
}

export default App;
